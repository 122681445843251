export enum CartStepperName {
    CART,
    INFORMATION_TRAVELERS,
    INFORMATION_USER,
    RESERVATION,
    ALREADY_PAID,
    REFUSED,
    PENDING,
    WAITING,
    PAYMENT_SUCCESS,
}
// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { List } from '../objects/list';
import { LocaleState } from '../objects/localeState';

const initialState: LocaleState = {
    current_locale: null,
    list: null,
    main_locale: null,
    previous_locale: null,
    translate_locale: []
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setCurrentLocale: (state, action: PayloadAction<number>) => {
            const translate_locale = state.translate_locale;
            const index = translate_locale.indexOf(action.payload);
            if (index !== -1) {
                translate_locale.splice(index, 1);
            }
            state.current_locale = action.payload;
            state.previous_locale = state.current_locale;
            state.translate_locale = translate_locale;
        },
        setList: (state, action: PayloadAction<List>) => {
            const { results } = action.payload;
            state.list = results;
            state.current_locale = results.length > 0 ? results[0].id : null;
        },
        setMainLocale: (state, action: PayloadAction<number>) => {
            state.main_locale = action.payload;
            state.current_locale = action.payload;
        },
        setTranslateLocale: (state, action: PayloadAction<number[]>) => {
            state.translate_locale = action.payload;
        }
    }
});


export const {
    setCurrentLocale,
    setList,
    setMainLocale,
    setTranslateLocale
} = localeSlice.actions;

export default localeSlice.reducer;
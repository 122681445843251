import restorers from "./restore";
import { Block } from "../objects/block";
import { compare, compareVersions } from "compare-versions";

type Options = {
    type: string,
    html: string,
    quotationCode: string | null
}

class Restorer {
    public restoreFromVersion(version: string, options: Options): Block<any> | null {
        const compatibleVersion = Object.keys(restorers).filter((item) => {
            return compare(item, version, '<=');
        }).sort(compareVersions).reverse()[0] ?? '0.0.0';
        const blockRestorer = (restorers[compatibleVersion] ?? {})[options.type];

        if (blockRestorer) {
            return blockRestorer.restore({
                version: compatibleVersion,
                html: options.html,
                quotationCode: options.quotationCode
            });
        }

        return null;
    }
}

export const restorer = new Restorer();

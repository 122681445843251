import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    styled,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import { clone, flatten, flattenDeep, isEqualWith } from "lodash";
import {
    DropResult,
    MailTemplateVisualEditorDropTarget,
    MailTemplateVisualEditorDropTargetProps
} from "../mailTemplateVisualEditorDropTarget";
import { MailTemplateVisualEditorOptionsSection } from "../mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorPaddingOptions } from "../mailTemplateVisualEditorPaddingOptions";
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import { MailTemplateVisualEditorBorderWidthOption } from "../mailTemplateVisualEditorBorderWidthOption";
import { MailTemplateVisualEditorBorderStyle, MailTemplateVisualEditorBorderStyleOption } from "../mailTemplateVisualEditorBorderStyleOption";
import { MailTemplateVisualEditorBorderColorOption } from "../mailTemplateVisualEditorBorderColorOption";
import { MailTemplateVisualEditorBorderCornersOption, MailTemplateVisualEditorCornersOption } from "../mailTemplateVisualEditorBorderCornersOption";
import { MailTemplateVisualEditorImageOption } from "../mailTemplateVisualEditorImageOption";
import {
    MailTemplateVisualEditorVerticalAlignmentOption
} from "../mailTemplateVisualEditorVerticalAlignmentOption";
import { BlockFeaturedComponentProps, DraggedItemData } from "../utils/block-features";
import { registerBlock, RegisteredBlocks } from "../utils/registered-blocks";
import { generateBlockId } from "../utils/block-id";
import { insertBlockAtIndex } from "../utils/blocks-order";
import { findBlockRecursively } from "../utils/find-block-recursively";
import { deleteVisualEditorBlock, setVisualEditorBlockOptions } from "../redux/actions";
import { MailTemplateVisualEditorBodyStyles } from "../objects/mailTemplateVisualEditorState";
import { Block } from "../objects/block";
import { BlockHtmlAttribute } from "../objects/blockHtmlAttributes";
import { Mode } from "../objects/mode";
import { AppState } from "../../../../../Reducers/Reducers";
import ColumnsIcon from '@mui/icons-material/ViewColumn';

type Options = {
    backgroundColor: string,
    backgroundImage: string | null,
    columns: ColumnOptions[],
    verticalAlignment: 'top' | 'middle' | 'bottom',
    disableResponsiveness: boolean
}

export type ColumnOptions = {
    id: string,
    ratio: number,
    padding: MailTemplateVisualEditorPaddingOptions,
    backgroundColor: string,
    backgroundImage: string | null,
    borderWidth: number,
    borderStyle: MailTemplateVisualEditorBorderStyle,
    borderCorners: MailTemplateVisualEditorCornersOption,
    borderColor: string,
    blocks: Block<any>[]
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}

type ColumnProps = ColumnOptions &
Pick<
    MailTemplateVisualEditorDropTargetProps,
    'onBlockReorder'
> & Pick<
    BlockFeaturedComponentProps<Options>,
    'onDropped'
> & {
    disableResponsiveness: boolean,
    columnsBlockId: number,
    onNewBlock: (type: string | symbol, index: number) => void,
    onDelete: (id: number) => void,
    onDuplicate: (id: number, index: number) => void
}

type ColumnPropertiesOptionsProps = {
    columnBlockId: number,
    columnBlockOptions: Options,
    column?: ColumnOptions,
    columnIndex: number
}

type LayoutPreviewProps = {
    selected: boolean,
    ratios: number[],
    onChangeLayout: (ratios: number[]) => void
}

type LayoutChangeConfirmationModalProps = {
    open: boolean,
    onConfirm: () => void,
    onClose: () => void
}

export const createColumn = (): ColumnOptions => ({
    id: (Math.random() * 10000).toFixed(0),
    ratio: 1 / 2,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    backgroundImage: null,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: '#000',
    borderCorners: {
        topLeft: 0,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 0
    },
    padding: {
        top: 5,
        bottom: 5,
        left: 10,
        right: 10
    },
    blocks: []
});

const Layouts: {
    ratios: number[]
}[] = [
    { ratios: [1] },
    { ratios: [1 / 2, 1 / 2] },
    { ratios: [1 / 3, 1 / 3, 1 / 3] },
    { ratios: [1 / 4, 1 / 4, 1 / 4, 1 / 4] },
    { ratios: [1 / 3, 2 / 3] },
    { ratios: [2 / 3, 1 / 3] },
    { ratios: [1 / 4, 3 / 4] },
    { ratios: [3 / 4, 1 / 4] }
];

function MailTemplateVisualEditorColumnsBlock(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.locale.current_locale);
    const blocks = useSelector((state: AppState) => state.mailTemplate.visualEditor.present.visualEditorBlocks).find((item) => {
        return item.locale === locale;
    })?.content;
    const [collected] = useDrop(() => ({
        accept: RegisteredBlocks.map((item) => item.type),
        collect: (monitor) => ({
            choosingBlock: monitor.canDrop()
        })
    }));

    const changeColumnOption = (
        options: Options,
        columnIndex: number,
        cb: (options: Options["columns"][0]) => Options["columns"][0]
    ): Options => {
        return {
            ...options,
            columns: props.options.columns.map((column, itemIndex) => {
                if (itemIndex === columnIndex) {
                    return cb(column);
                }
                return column;
            })
        };
    };

    const onNewBlock = (columnIndex: number, index: number, type: string | symbol) => {
        const registeredBlock = RegisteredBlocks.find((item) => item.type === type);
        if (registeredBlock && locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                changeColumnOption(
                    props.options,
                    columnIndex,
                    (columnOptions) => ({
                        ...columnOptions,
                        blocks: [
                            ...columnOptions.blocks.slice(0, index),
                            registeredBlock.factory(),
                            ...columnOptions.blocks.slice(index)
                        ]
                    })
                )
            );
            dispatch(dispatchData);
        }
    };

    const onBlockReorder = (
        columnIndex: number,
        options: Parameters<MailTemplateVisualEditorDropTargetProps['onBlockReorder']>[0]
    ) => {
        if (options.sourceName === 'visual-editor-drag-area') {
            const block = blocks?.find((item) => item.getId() === options.id);
            if (block && locale !== null) {
                //delete block from visual editor drag area
                const deleteDispatchData = deleteVisualEditorBlock(
                    locale,
                    options.id
                );
                dispatch(deleteDispatchData);
                //add block in column
                const addDispatchData = setVisualEditorBlockOptions(
                    locale,
                    props.id,
                    changeColumnOption(
                        props.options,
                        columnIndex,
                        (columnOptions) => ({
                            ...columnOptions,
                            blocks: [
                                ...columnOptions.blocks,
                                block
                            ]
                        })
                    )
                );
                dispatch(addDispatchData);
            }
        } else if (options.sourceName.includes('columns-block')) {
            const block = findBlockRecursively(blocks ?? [], options.id);
            if (locale !== null && block) {
                //insert block in column
                let resultOptions = changeColumnOption(
                    props.options,
                    columnIndex,
                    (columnOptions) => ({
                        ...columnOptions,
                        blocks: insertBlockAtIndex({
                            index: options.index,
                            block,
                            blocks: columnOptions.blocks
                        })
                    })
                );

                //remove block from all other columns or from current column in current columns block
                //(a column can contain many blocks)
                resultOptions = {
                    ...resultOptions,
                    columns: resultOptions.columns.map((column, index) => {
                        if (index !== columnIndex) {
                            return {
                                ...column,
                                blocks: column.blocks.filter((item) => item.getId() !== block.getId())
                            };
                        }
                        return {
                            ...column,
                            blocks: column.blocks.filter((item, index) => {
                                return item.getId() !== block.getId() || index === options.index;
                            })
                        };
                    })
                };
                const dispatchData = setVisualEditorBlockOptions(
                    locale,
                    props.id,
                    resultOptions
                );
                dispatch(dispatchData);

                //remove block from the other block if drag came from another columns block
                if (options.sourceName !== options.targetName) {
                    const columnsBlockId = parseInt(options.sourceName.replace('columns-block-', ''));
                    const columnsBlock = blocks?.find((item) => item.getId() === columnsBlockId);

                    if (columnsBlock) {
                        const result = columnsBlock.getOptions() as Options;
                        result.columns = result.columns.map((column) => {
                            return {
                                ...column,
                                blocks: column.blocks.filter((item) => item.getId() !== options.id)
                            };
                        });
                        const dispatchData = setVisualEditorBlockOptions(
                            locale,
                            columnsBlockId,
                            result
                        );
                        dispatch(dispatchData);
                    }
                }
            }
        }
    };

    const onDuplicate = (columnIndex: number, id: number, index: number) => {
        const block = props.options.columns[columnIndex]?.blocks.find((item) => {
            return item.getId() === id;
        });
        if (locale !== null && block) {
            const addDispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                changeColumnOption(
                    props.options,
                    columnIndex,
                    (columnOptions) => ({
                        ...columnOptions,
                        blocks: insertBlockAtIndex({
                            index,
                            block: block.clone(),
                            blocks: columnOptions.blocks
                        })
                    })
                )
            );
            dispatch(addDispatchData);
        }
    };

    const onDelete = (columnIndex: number, id: number) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                changeColumnOption(
                    props.options,
                    columnIndex,
                    (columnOptions) => ({
                        ...columnOptions,
                        blocks: columnOptions.blocks.filter((item) => {
                            return item.getId() !== id;
                        })
                    })
                )
            );
            dispatch(dispatchData);
        }
    };

    //needed to detect if column item came from columns block and was dropped in visual editor drag area
    const onDropped = (item: DraggedItemData, dropResult: DropResult | null) => {
        if (locale !== null && dropResult && !dropResult.targetName.includes('columns-block')) {
            //remove block from current columns block
            const options = {
                ...props.options,
                columns: props.options.columns.map((column) => {
                    return {
                        ...column,
                        blocks: column.blocks.filter((block) => block.getId() !== item.id)
                    };
                })
            };
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                options
            );
            dispatch(dispatchData);
        }
    };

    let verticalAlignment: React.CSSProperties['alignItems'] = 'flex-start';

    switch (props.options.verticalAlignment) {
        case 'top': verticalAlignment = 'flex-start'; break;
        case 'middle': verticalAlignment = 'center'; break;
        case 'bottom': verticalAlignment = 'flex-end'; break;
    }

    return (
        <ColumnsContainer
            sx={{
                alignItems: collected.choosingBlock ? 'stretch' : verticalAlignment,
                backgroundColor: props.options.backgroundColor,
                backgroundImage: props.options.backgroundImage ?
                    `url(${props.options.backgroundImage})` :
                    undefined,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover'
            }}
        >
            {
                props.options.columns.map((item, columnIndex) => (
                    <Column
                        {...item}
                        columnsBlockId={props.id}
                        key={columnIndex}
                        onNewBlock={(type, index) => onNewBlock(columnIndex, index, type)}
                        onBlockReorder={(options) => onBlockReorder(columnIndex, options)}
                        onDuplicate={(id, index) => onDuplicate(columnIndex, id, index)}
                        onDelete={(id) => onDelete(columnIndex, id)}
                        onDropped={onDropped}
                        disableResponsiveness={props.options.disableResponsiveness}
                    />
                ))
            }
        </ColumnsContainer>
    );
}

function MailTemplateVisualEditorColumnsBlockOptions(props: OptionsComponentProps): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const locale = useSelector((state: AppState) => state.locale.current_locale);
    const [openLayouChangeConfirmation, setOpenLayouChangeConfirmation] = useState(false);
    const [ratios, setRatios] = useState<number[]>([]);
    const [columnPropertiesTab, setColumnPropertiesTab] = useState(0);

    const onCloseLayoutChangeConfirmation = () => {
        setOpenLayouChangeConfirmation(false);
    };

    const onConfirmLayouChange = () => {
        changeLayout(ratios);
        onCloseLayoutChangeConfirmation();
    };

    const onChangeColumnPropertiesTab = (event: React.SyntheticEvent<Element, Event>, tab: number) => {
        setColumnPropertiesTab(tab);
    };

    const onChangeLayout = (ratios: number[]) => {
        if (ratios.length < props.options.columns.length) {
            setColumnPropertiesTab(0);
        }

        if (
            ratios.length < props.options.columns.length &&
            props.options.columns.slice(ratios.length).find((item) => item.blocks.length > 0) !== undefined
        ) {
            setRatios(ratios);
            setOpenLayouChangeConfirmation(true);
        } else {
            changeLayout(ratios);
        }
    };

    const changeLayout = (ratios: number[]) => {
        if (locale !== null) {
            let resultColumns = props.options.columns.map((column, index) => ({
                ...column,
                ratio: ratios[index] ?? column.ratio
            }));

            if (ratios.length < props.options.columns.length) {
                resultColumns = resultColumns.slice(0, ratios.length);
            } else if (ratios.length > props.options.columns.length) {
                resultColumns = resultColumns.concat(
                    new Array(ratios.length - props.options.columns.length).fill(null).map((item, index) => ({
                        ...createColumn(),
                        ratio: ratios[index + props.options.columns.length] ?? 0
                    }))
                );
            }

            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                {
                    ...props.options,
                    columns: resultColumns
                }
            );
            dispatch(dispatchData);
        }
    };

    function onChangeOption<K extends keyof OptionsComponentProps["options"]>(
        key: K,
        value: OptionsComponentProps["options"][K]
    ): void {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                {
                    ...props.options,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    }

    const compareRatios = (a: number[], b: number[]) => {
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            const numberA = a[i];
            const numberB = b[i];

            if (numberA === undefined || numberB === undefined) {
                return false;
            }

            if (numberA.toFixed(4) !== numberB.toFixed(4)) {
                return false;
            }
        }
        return true;
    };

    return (
        <div>
            <LayoutChangeConfirmationModal
                open={openLayouChangeConfirmation}
                onConfirm={onConfirmLayouChange}
                onClose={onCloseLayoutChangeConfirmation}
            />
            <MailTemplateVisualEditorOptionsSection>
                <Typography>
                    {t<string>('shared.mail-template-visual-editor-columns-block-layout')}
                </Typography>
                <Grid spacing={3} container>
                    {
                        Layouts.map((layout, index) => (
                            <Grid key={index} xs={6} item>
                                <LayoutPreview
                                    selected={
                                        isEqualWith(
                                            props.options.columns.map((item) => item.ratio),
                                            layout.ratios,
                                            compareRatios
                                        )
                                    }
                                    ratios={layout.ratios}
                                    onChangeLayout={onChangeLayout}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </MailTemplateVisualEditorOptionsSection>
            <MailTemplateVisualEditorImageOption
                title={t<string>('shared.mail-template-visual-editor-background-image')}
                url={props.options.backgroundImage}
                onChangeUrl={(url) => onChangeOption('backgroundImage', url)}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption('backgroundColor', color)}
            />
            <MailTemplateVisualEditorVerticalAlignmentOption
                alignment={props.options.verticalAlignment}
                onChangeAlignment={(alignment) => onChangeOption('verticalAlignment', alignment)}
            />
            <MailTemplateVisualEditorOptionsSection>
                <FormGroup>
                    <FormControlLabel
                        label={t<string>('shared.mail-template-visual-editor-disable-responsiveness')}
                        control={<Checkbox checked={props.options.disableResponsiveness} />}
                        onChange={(_, checked) => onChangeOption('disableResponsiveness', checked)}
                    />
                </FormGroup>
            </MailTemplateVisualEditorOptionsSection>
            <MailTemplateVisualEditorOptionsSection>
                <Typography>
                    {t<string>('shared.mail-template-visual-editor-columns-block-column-properties')}
                </Typography>
                <Tabs value={columnPropertiesTab} onChange={onChangeColumnPropertiesTab}>
                    {
                        props.options.columns.map((column, index) => (
                            <Tab
                                key={index}
                                label={`${t<string>('shared.mail-template-visual-editor-columns-block-column')} ${index + 1}`}
                            />
                        ))
                    }
                </Tabs>
                <ColumnPropertiesOptions
                    columnBlockId={props.id}
                    columnBlockOptions={props.options}
                    column={props.options.columns[columnPropertiesTab]}
                    columnIndex={columnPropertiesTab}
                />
            </MailTemplateVisualEditorOptionsSection>
        </div>
    );
}

const ColumnsContainer = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap'
}));

const Column = (props: ColumnProps): JSX.Element => {
    const draggedBlockId = useSelector((state: AppState) => state.mailTemplate.others.visualEditorDraggedBlockId);
    const mode = useSelector((state: AppState) => state.mailTemplate.visualEditor.present.visualEditorMode);

    const {
        blocks,
        ratio,
        padding,
        backgroundColor,
        backgroundImage,
        borderWidth,
        borderStyle,
        borderColor,
        borderCorners,
        onDuplicate,
        onDelete,
        onDropped,
        onNewBlock,
        ...passThrough
    } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor,
                backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                width: mode === Mode.MOBILE && !props.disableResponsiveness ? '100%' : ratio * 100 + '%',
                border: `${borderWidth}px ${borderStyle} ${borderColor}`,
                borderTopLeftRadius: `${borderCorners.topLeft}px`,
                borderTopRightRadius: `${borderCorners.topRight}px`,
                borderBottomLeftRadius: `${borderCorners.bottomLeft}px`,
                borderBottomRightRadius: `${borderCorners.bottomRight}px`,
                ...(
                    blocks.length > 0 ?
                        {
                            paddingTop: `${padding.top}px`,
                            paddingBottom: `${padding.bottom}px`,
                            paddingLeft: `${padding.left}px`,
                            paddingRight: `${padding.right}px`
                        } :
                        {
                            padding: '10px'
                        }
                )
            }}
        >
            {
                draggedBlockId !== blocks[0]?.getId() &&
                <MailTemplateVisualEditorDropTarget
                    {...passThrough}
                    index={0}
                    onNewBlock={(type) => onNewBlock(type, 0)}
                    targetName={`columns-block-${props.columnsBlockId}`}
                    rejectedBlockTypes={['columns']}
                    forceShow={blocks.length === 0}
                />
            }
            {
                blocks.map((block, index) => {
                    const Component = RegisteredBlocks.find((item) => {
                        return item.type === block.getType();
                    })?.component;

                    if (!Component) {
                        return null;
                    }

                    return (
                        <React.Fragment key={block.getId()}>
                            <Component
                                id={block.getId()}
                                sourceName={`columns-block-${props.columnsBlockId}`}
                                options={block.getOptions()}
                                onDuplicate={() => onDuplicate(block.getId(), index + 1)}
                                onDelete={() => onDelete(block.getId())}
                                onDropped={onDropped}
                            />
                            <MailTemplateVisualEditorDropTarget
                                {...passThrough}
                                index={index + 1}
                                onNewBlock={(type) => onNewBlock(type, index + 1)}
                                targetName={`columns-block-${props.columnsBlockId}`}
                                rejectedBlockTypes={['columns']}
                            />
                        </React.Fragment>
                    );
                }).filter((item) => item)
            }
        </Box>
    );
};

const ColumnPropertiesOptions = (props: ColumnPropertiesOptionsProps): JSX.Element | null => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.locale.current_locale);


    const onChangeOption = (key: keyof OptionsComponentProps["options"]["columns"][0], value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.columnBlockId,
                {
                    ...props.columnBlockOptions,
                    columns: props.columnBlockOptions.columns.map((column, index) => {
                        if (index === props.columnIndex) {
                            return {
                                ...column,
                                [key]: value
                            };
                        }
                        return column;
                    })
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangePadding = (type: keyof MailTemplateVisualEditorPaddingOptions, value: number) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.columnBlockId,
                {
                    ...props.columnBlockOptions,
                    columns: props.columnBlockOptions.columns.map((column, index) => {
                        if (index === props.columnIndex) {
                            return {
                                ...column,
                                padding: {
                                    ...column.padding,
                                    [type]: value
                                }
                            };
                        }
                        return column;
                    })
                }
            );
            dispatch(dispatchData);
        }
    };

    function onChangeCorner<K extends keyof OptionsComponentProps["options"]["columns"][0]["borderCorners"]>(
        type: K,
        value: OptionsComponentProps["options"]["columns"][0]["borderCorners"][K]
    ): void {
        if (props.column) {
            onChangeOption("borderCorners", { ...props.column.borderCorners, [type]: value });
        }
    }

    if (!props.column) {
        return null;
    }

    return (
        <div>
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.column.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption("backgroundColor", color)}
            />
            <MailTemplateVisualEditorImageOption
                title={t<string>('shared.mail-template-visual-editor-background-image')}
                url={props.column.backgroundImage}
                onChangeUrl={(url) => onChangeOption('backgroundImage', url)}
            />
            <MailTemplateVisualEditorBorderWidthOption
                width={props.column.borderWidth}
                onChangeWidth={(width) => onChangeOption("borderWidth", width)}
            />
            <MailTemplateVisualEditorBorderStyleOption
                style={props.column.borderStyle}
                onChangeStyle={(style) => onChangeOption("borderStyle", style)}
            />
            <MailTemplateVisualEditorBorderColorOption
                color={props.column.borderColor}
                onChangeColor={(color) => onChangeOption("borderColor", color)}
            />
            <MailTemplateVisualEditorBorderCornersOption
                corners={props.column.borderCorners}
                onChangeCorner={onChangeCorner}
            />
            <MailTemplateVisualEditorPaddingOptions
                padding={props.column.padding}
                onChangePadding={onChangePadding}
            />
        </div>
    );
};

const LayoutChangeConfirmationModal = (props: LayoutChangeConfirmationModalProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Dialog open={props.open} >
            <DialogTitle>{ t<string>('shared.mail-template-visual-editor-columns-block-layout-change-confirmation-title') }</DialogTitle>
            <DialogContent>
                <DialogContentText>{ t<string>('shared.mail-template-visual-editor-columns-block-layout-change-confirmation') }</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{ t<string>('cancel') }</Button>
                <Button onClick={props.onConfirm} autoFocus>{ t<string>('shared.validate') }</Button>
            </DialogActions>
        </Dialog>
    );
};

const LayoutPreview = (props: LayoutPreviewProps): JSX.Element => {
    return (
        <LayoutPreviewContainer onClick={() => props.onChangeLayout(props.ratios)}>
            {
                props.ratios.map((ratio, index) => (
                    <LayoutPreviewBox
                        key={index}
                        sx={(theme) => ({
                            width: ratio * 100 + '%',
                            backgroundColor: props.selected ?
                                '#ddd' :
                                '#fff',
                            color: theme.palette.getContrastText(
                                props.selected ?
                                    '#ddd' :
                                    '#fff'
                            )
                        })}
                    >
                        {props.selected && index + 1}
                    </LayoutPreviewBox>
                ))
            }
        </LayoutPreviewContainer>
    );
};

const LayoutPreviewContainer = styled('div')((props) => ({
    "display": 'flex',
    "border": '2px solid #ccc',
    "borderLeftWidth": 1,
    "borderRightWidth": 1,
    "borderRadius": props.theme.shape.borderRadius,
    "cursor": 'pointer',
    '&:hover': {
        boxShadow: props.theme.shadows[10]
    }
}));

const LayoutPreviewBox = styled(Box)((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    height: 36,
    fontSize: props.theme.typography.caption.fontSize
}));

export class ColumnsBlock implements Block<Options> {
    private id;
    private options: Options;

    public constructor() {
        this.id = generateBlockId();
        this.options = {
            backgroundColor: '#fff',
            backgroundImage: null,
            columns: (Layouts[0] ?? { ratios: [] }).ratios.map((ratio) => ({
                ...createColumn(),
                ratio
            })),
            verticalAlignment: 'top',
            disableResponsiveness: false
        };
    }

    public getType(): string {
        return 'columns';
    }

    public getId(): number {
        return this.id;
    }

    public getExtraStyles(): string[] {
        const blockStyles: string[] = flattenDeep(
            this.options.columns.map((column) => {
                return column.blocks.map((block) => {
                    return block.getExtraStyles ?
                        block.getExtraStyles() :
                        [];
                });
            })
        );
        const styles = this.options.columns.map((column) => {
            return column.backgroundImage ?
                `
                    .columns-block-column.${column.id} {
                        background-image: url(${column.backgroundImage});
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                    }
                ` :
                null;
        }).filter((item) => item) as string[];
        return styles.concat(blockStyles);
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public getExtraHtmlAttributes(): BlockHtmlAttribute[] {
        return flattenDeep(
            this.options.columns.map((column) => {
                return column.blocks.map((block) => {
                    return block.getExtraHtmlAttributes ?
                        block.getExtraHtmlAttributes() :
                        [];
                });
            })
        );
    }

    public renderMjml(
        bodyStyles: MailTemplateVisualEditorBodyStyles,
        quotationCode: string | null
    ): string {
        let columnContents = this.options.columns.map((column) => {
            return {
                column,
                content: column.blocks.map((block) => block.renderMjml(bodyStyles, quotationCode)).join('\n')
            };
        }).map(({ column, content }) => {
            const border = column.borderWidth > 0 ?
                `border="${column.borderWidth}px ${column.borderStyle} ${column.borderColor}"` :
                '';
            return `<mj-column
                        width="${column.ratio * 100}%"
                        background-color="${column.backgroundColor}"
                        padding-top="${column.padding.top}px"
                        padding-bottom="${column.padding.bottom}px"
                        padding-left="${column.padding.left}px"
                        padding-right="${column.padding.right}px"
                        ${border}
                        border-radius="${column.borderCorners.topLeft}px ${column.borderCorners.topRight}px ${column.borderCorners.bottomRight}px ${column.borderCorners.bottomLeft}px"
                        vertical-align="${this.options.verticalAlignment}"
                        css-class="columns-block-column ${column.id}"
                    >
                        ${content}
                    </mj-column>`;
        }).join('');
        columnContents = this.options.disableResponsiveness ?
            `
                <mj-group css-class="disable-responsiveness">
                    ${columnContents}
                </mj-group>
            ` :
            columnContents;
        const content = `
            <mj-section
                text-align="left"
                background-color="${this.options.backgroundColor}"
                background-url="${this.options.backgroundImage ?? ''}"
                background-position="center center"
                background-repeat="no-repeat"
                background-size="cover"
                css-class="columns-block-root"
            >
                ${columnContents}
            </mj-section>
        `;
        return content;
    }

    public clone(options?: Options): Block<Options> {
        let block: ColumnsBlock;
        const blockOptions = options ?? this.getOptions();

        if (options) {
            block = clone(this);
            block.setOptions({
                ...blockOptions,
                columns: blockOptions.columns.map((column) => {
                    return {
                        ...column,
                        blocks: column.blocks.map((block) => block.clone(block.getOptions()))
                    };
                })
            });
        } else {
            block = new ColumnsBlock();
            block.setOptions({
                ...blockOptions,
                columns: blockOptions.columns.map((column) => {
                    return {
                        ...column,
                        blocks: column.blocks.map((block) => block.clone())
                    };
                })
            });
        }

        return block;
    }

    public findBlock(id: number): Block<Options> | undefined {
        if (id === this.getId()) {
            return this;
        }
        return flatten(this.options.columns.map((item) => item.blocks)).find((block) => {
            return block.getId() === id;
        });
    }
}

registerBlock({
    type: 'columns',
    icon: <ColumnsIcon />,
    label: 'shared.mail-template-visual-editor-columns-block-label',
    component: MailTemplateVisualEditorColumnsBlock,
    optionsComponent: MailTemplateVisualEditorColumnsBlockOptions,
    htmlAttributes: [],
    styles: [
        `
            .columns-block-column table {
                border-collapse: separate;
            }
        `
    ],
    factory: () => new ColumnsBlock()
});

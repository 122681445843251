// Dependencies
import axios from 'axios';
import i18next from 'i18next';
import { Loader } from '@googlemaps/js-api-loader';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { lazy, FC, ReactElement, ComponentType, useEffect } from 'react';
// Core & Lab
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Components
import Loading from '../component/loading/loading';
// Header
const Header = lazy(() => import('../component/header/header'));
// Footer
const Footer = lazy(() => import('../component/footer/footer'));
// Trip Cost
import TripCost from '../component/trip/tripCost';
// Utils
import ErrorBoundary from './errorBoundary';
// Functions
import getCssClass from '../functions/getCssClass';
import getMuiLanguage from '../functions/getMuiLanguage';
import { useAutoLogin } from '../functions/useAutoLogin';
// Actions
import { setList } from '../component/locale/redux/reducer';
import { setEvent } from '../component/event/redux/reducer';
import { setProviders } from '../component/provider/redux/reducer';
import { setCurrencies } from '../component/currency/redux/reducer';
import { setConfig, setGoogle } from '../component/header/redux/reducer';
// Types
import { AppState } from '../reducers';

type PrivateRouteProps = {
    component: ComponentType
};

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...rest }): ReactElement => {
    const dispatch = useDispatch();
    const params = useParams();
    const client_id = useSelector((state: AppState) => state.header.client_id);
    const providers = useSelector((state: AppState) => state.provider.providers);
    const currencies = useSelector((state: AppState) => state.currency.currencies);
    const { loggingIn } = useAutoLogin();
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    if (user_id === null || token === null) {
        return <Navigate replace to={ '/home' }/>;
    }
    useEffect(() => {
        if (providers === null) {
            axios({
                method: 'GET',
                url: `${API_HREF}providers/`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                params: {
                    limit: 250
                }
            }).then((response) => {
                dispatch(setProviders(response.data.results));
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [providers]);
    useEffect(() => {
        if (currencies === null) {
            axios({
                method: 'GET',
                url: `${API_HREF}currencies/`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                params: {
                    limit: 250
                }
            }).then((response) => {
                dispatch(setCurrencies(response.data.results));
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [currencies]);
    useEffect(() => {
        if (client_id === null) {
            const result = /.([^.]+)/.exec(window.location.host);
            let customer_identifier = result !== null ? result[0] : 'vat-dev';
            let domain_name = window.location.host;
            if (window.location.host === 'localhost:3000') {
                customer_identifier = 'dev';
                // domain_name = 'dev.facilitatrip.com';
                domain_name = 'dev.tripmci.com';
            }
            axios({
                method: 'GET',
                url: `${API_HREF}instances/?customer_identifier=${customer_identifier}&domain_name=${domain_name}`
            }).then((response) => {
                dispatch(setConfig(response.data));
                if (response.data.favicon !== undefined && response.data.favicon !== null && response.data.favicon.thumbnail_little !== undefined && response.data.favicon.url !== undefined) {
                    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    // @ts-ignore
                    link.type = 'image/x-icon';
                    // @ts-ignore
                    link.rel = 'shortcut icon';
                    // @ts-ignore
                    link.href = (response.data.favicon.thumbnail_little !== null ? response.data.favicon.thumbnail_little : response.data.favicon.url);
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                let google_key = null;
                for (let i = 0; i < response.data.keys.length; i++) {
                    if (response.data.keys[i].identifier === 'google_api') {
                        google_key = response.data.keys[i].value;
                        break;
                    }
                }
                const loader = new Loader({
                    apiKey: google_key,
                    version: 'beta',
                    libraries: ['places']
                });
                loader
                    .load()
                    .then((google) => {
                        dispatch(setGoogle(google));
                    })
                    .catch(e => {
                        // do something
                    });
                const storedToken = localStorage.getItem('token');
                axios({
                    method: 'GET',
                    url: `${API_HREF}client/${response.data.client_id}/event-site/get_instance/`,
                    headers: {
                        Authorization: storedToken ? `Token ${storedToken}` : undefined
                    },
                    params: {
                        sub_domain: params.slug
                    }
                }).then((response) => {
                    const text_color = getCssClass('.font-color');
                    if (text_color !== null) {
                        // @ts-ignore
                        text_color.style.setProperty('color', response.data.text_color, 'important');
                    }
                    const fieldset_color = getCssClass('fieldset');
                    if (fieldset_color !== null) {
                        // @ts-ignore
                        fieldset_color.style.setProperty('color', response.data.text_color, 'important');
                        // @ts-ignore
                        fieldset_color.style.setProperty('border-color', response.data.text_color, 'important');
                    }
                    const label_color = getCssClass('label');
                    if (label_color !== null) {
                        // @ts-ignore
                        label_color.style.setProperty('color', response.data.text_color, 'important');
                    }
                    const background_color = getCssClass('.background-color');
                    if (background_color !== null) {
                        // @ts-ignore
                        background_color.style.setProperty('background-color', response.data.background_color, 'important');
                    }
                    const button_color = getCssClass('.button-color');
                    if (button_color !== null) {
                        // @ts-ignore
                        button_color.style.setProperty('color', response.data.button_color, 'important');
                        // @ts-ignore
                        button_color.style.setProperty('border-color', response.data.button_color, 'important');
                    }
                    const loading_button_color = getCssClass('.loading-button-color');
                    if (loading_button_color !== null) {
                        // @ts-ignore
                        loading_button_color.style.setProperty('border-color', response.data.button_color, 'important');
                    }
                    const inverted_button_color = getCssClass('.inverted-button-color');
                    if (inverted_button_color !== null) {
                        // @ts-ignore
                        inverted_button_color.style.setProperty('background-color', response.data.button_color, 'important');
                        // @ts-ignore
                        inverted_button_color.style.setProperty('border-color', response.data.button_color, 'important');
                    }
                    const border_color = getCssClass('.border-color');
                    if (border_color !== null) {
                        // @ts-ignore
                        border_color.style.setProperty('border-color', response.data.button_color, 'important');
                    }
                    dispatch(setEvent(response.data));
                }).catch((error) => {
                    console.log('error : ', error);
                });
            }).catch((error) => {
                console.log('error : ', error);
            });
            axios({
                method: 'GET',
                url: `${API_HREF}locales/?ordering=id`
            }).then((response) => {
                dispatch(setList(response.data));
            }).catch((error) => {
                console.log('error : ', error);
            });
        }
    }, [client_id]);

    if (client_id === null || providers === null || currencies === null || loggingIn) {
        return <Loading/>;
    }
    const locales_cpy : { [key: string]: any } = locales;
    return (
        <ThemeProvider theme={ createTheme({}, locales_cpy[getMuiLanguage(i18next.language)]) }>
            <Header/>
            <main className={ 'main' }>
                <ErrorBoundary>
                    <Component {...rest}/>
                </ErrorBoundary>
            </main>
            <Footer/>
            <TripCost/>
        </ThemeProvider>
    );
};

export default PrivateRoute;
import { isNumber } from "lodash";
import { MailTemplateState } from "../objects/mailTemplateState";
import {
    ADD_VISUAL_EDITOR_BLOCK,
    CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT,
    CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT,
    DELETE_VISUAL_EDITOR_BLOCK,
    DUPLICATE_VISUAL_EDITOR_BLOCK,
    MailTemplateActionTypes,
    REDO_VISUAL_EDITOR,
    REORDER_VISUAL_EDITOR_BLOCKS,
    RESET_CREATE_FORM,
    RESET_EDITORS,
    RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML,
    SELECT_VISUAL_EDITOR_BLOCK,
    SET_AUTOCOMPLETE,
    SET_CREATE_ACTION,
    SET_CREATE_EXTRA_PARAMS,
    SET_CREATE_NAME,
    SET_CREATE_SUBJECT,
    SET_EDITOR_CHOICE,
    SET_GOOGLE_FONTS,
    SET_HTML_CODE_EDITOR_CONTENT,
    SET_LOADING,
    SET_MAIL_TEMPLATES,
    SET_QUERY,
    SET_RECIPIENTS,
    SET_VISUAL_EDITOR_BLOCK_OPTIONS,
    SET_VISUAL_EDITOR_BODY_STYLES,
    SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID,
    SET_VISUAL_EDITOR_MODE,
    TOGGLE_CANCEL_MODAL,
    TOGGLE_CREATE,
    UNDO_VISUAL_EDITOR,
    UNSELECT_VISUAL_EDITOR_BLOCK
} from "./actionTypes";
import { RegisteredBlocks } from "../utils/registered-blocks";
import { rgbToHex } from "../utils/rgbToHex";
import { htmlWalker, MailTemplateBodyHtmlWalkingStrategy } from "../utils/html-walker";
import { createDomObjectsFromHtml } from "../utils/dom";
import { findBlockRecursively } from "../utils/find-block-recursively";
import { insertBlockAtIndex } from "../utils/blocks-order";
import { Block } from "../objects/block";
import { GoogleFont } from "../objects/googleFont";
import DefaultHtml from "../assets/mail-template-default.html";
import undoable, { includeAction } from "redux-undo";
import { combineReducers } from "redux";
import { MailTemplateVisualEditorState } from "../objects/mailTemplateVisualEditorState";
import { ColumnsBlock } from "../blocks/mailTemplateVisualEditorColumnsBlock";
import { Mode } from "../objects/mode";
import { restorer } from "../blocks/restorer";
import { extractVersionFromHtml } from "../utils/extractVersionFromHtml";

const defaultFont: GoogleFont = {
    name: 'Roboto',
    url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap&global=true'
};

const initialState: MailTemplateState = {
    autocomplete: { state: 'loading' },
    cancel_modal: false,
    create: false,
    create_action: null,
    create_extra_params: {
        activated: true,
        replyable: true
    },
    create_name: [],
    create_subject: [],
    editorChoice: null,
    googleFonts: [],
    queries: [],
    htmlCodeEditorContents: [],
    mailTemplates: null,
    loading: false,
    visualEditorDraggedBlockId: null,
    visualEditorSelectedBlockId: null,
    recipients: { state: 'loading' }
};

const initialVisualEditorState: MailTemplateVisualEditorState = {
    visualEditorMode: Mode.DESKTOP,
    visualEditorBlocks: [],
    visualEditorBodyStyles: {
        fontFamily: defaultFont,
        backgroundColor: "#fff",
        width: 600,
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        innerBorder: {
            color: '#000',
            width: 0
        },
        innerBackgroundColor: '#fff',
        innerPadding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
};

const MailTemplateReducer = (state: MailTemplateState = initialState, action: MailTemplateActionTypes): MailTemplateState => {
    switch (action.type) {
        case CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT: {
            const firstContent = state.htmlCodeEditorContents[0];
            const contents = state.htmlCodeEditorContents.find(({ locale }) => locale === action.payload.locale);

            if (!contents && firstContent) {
                return {
                    ...state,
                    htmlCodeEditorContents: state.htmlCodeEditorContents.concat([
                        {
                            locale: action.payload.locale,
                            content: firstContent.content
                        }
                    ])
                };
            } else if (!contents && !firstContent) {
                return {
                    ...state,
                    htmlCodeEditorContents: state.htmlCodeEditorContents.concat([
                        { locale: action.payload.locale, content: DefaultHtml }
                    ])
                };
            }

            return state;
        }
        case RESET_CREATE_FORM: {
            return {
                ...state,
                create_action: 'USER_EMAIL_VERIFICATION',
                create_name: [],
                create_subject: [],
                create_extra_params: {
                    activated: true,
                    replyable: true
                }
            };
        }
        case RESET_EDITORS: {
            return {
                ...state,
                editorChoice: null,
                htmlCodeEditorContents: []
            };
        }
        case SET_AUTOCOMPLETE: {
            return {
                ...state,
                autocomplete: action.payload
            };
        }
        case SET_CREATE_ACTION: {
            return { ...state, create_action: action.payload };
        }
        case SET_CREATE_EXTRA_PARAMS: {
            return { ...state, create_extra_params: action.payload };
        }
        case SET_CREATE_NAME: {
            const current = state.create_name.find((item) => item.locale === action.payload.locale);

            if (!current) {
                return {
                    ...state,
                    create_name: state.create_name.concat([action.payload])
                };
            }

            return {
                ...state,
                create_name: state.create_name.map((item) => {
                    if (item.locale === action.payload.locale) {
                        return {
                            ...item,
                            value: action.payload.value
                        };
                    }
                    return item;
                })
            };
        }
        case SET_CREATE_SUBJECT: {
            const current = state.create_subject.find((item) => item.locale === action.payload.locale);

            if (!current) {
                return {
                    ...state,
                    create_subject: state.create_subject.concat([action.payload])
                };
            }

            return {
                ...state,
                create_subject: state.create_subject.map((item) => {
                    if (item.locale === action.payload.locale) {
                        return {
                            ...item,
                            value: action.payload.value
                        };
                    }
                    return item;
                })
            };
        }
        case SET_EDITOR_CHOICE: {
            return { ...state, editorChoice: action.payload };
        }
        case SET_GOOGLE_FONTS: {
            return { ...state, googleFonts: action.payload };
        }
        case SET_HTML_CODE_EDITOR_CONTENT: {
            const index = state.htmlCodeEditorContents.findIndex((item) => {
                return item.locale === action.payload.locale;
            });

            if (index < 0) {
                return {
                    ...state,
                    htmlCodeEditorContents: state.htmlCodeEditorContents.concat([action.payload])
                };
            }

            return {
                ...state,
                htmlCodeEditorContents: [
                    ...state.htmlCodeEditorContents.slice(0, index),
                    action.payload,
                    ...state.htmlCodeEditorContents.slice(index + 1)
                ]
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case SET_MAIL_TEMPLATES: {
            return {
                ...state,
                mailTemplates: action.payload
            };
        }
        case SET_QUERY: {
            const currentQuery = state.queries.find((query) => query.key === action.payload.key);
            if (currentQuery) {
                return {
                    ...state,
                    queries: state.queries.map((query) => query.key === action.payload.key ? action.payload : query)
                };
            }
            return {
                ...state,
                queries: [...state.queries, action.payload]
            };
        }
        case SET_RECIPIENTS: {
            return {
                ...state,
                recipients: action.payload
            };
        }
        case TOGGLE_CANCEL_MODAL: {
            return { ...state, cancel_modal: !state.cancel_modal };
        }
        case TOGGLE_CREATE: {
            return { ...state, create: !state.create };
        }
        case SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID: {
            return { ...state, visualEditorDraggedBlockId: action.payload };
        }
        case UNSELECT_VISUAL_EDITOR_BLOCK: {
            return { ...state, visualEditorSelectedBlockId: null };
        }
        case SELECT_VISUAL_EDITOR_BLOCK: {
            return { ...state, visualEditorSelectedBlockId: action.payload };
        }
        case DELETE_VISUAL_EDITOR_BLOCK: {
            return {
                ...state,
                visualEditorSelectedBlockId: null,
                visualEditorDraggedBlockId: null
            };
        }
        default: return state;
    }
};

const VisualEditorReducer = (
    state: MailTemplateVisualEditorState = initialVisualEditorState,
    action: MailTemplateActionTypes
): MailTemplateVisualEditorState => {
    switch (action.type) {
        case ADD_VISUAL_EDITOR_BLOCK: {
            const blockOptions = RegisteredBlocks.find((item) => item.type === action.payload.type);
            const blocks = state.visualEditorBlocks.find(({ locale }) => locale === action.payload.locale);
            const prevBlockIndex = blocks?.content.findIndex((block) => block.getId() === action.payload.prevBlockId);
            if (blockOptions) {
                const newBlocks = [...(blocks?.content ?? [])];
                const block = blockOptions.factory();
                newBlocks.splice(
                    (isNumber(prevBlockIndex) ? prevBlockIndex : -1) + 1,
                    0,
                    block
                );
                let replaced = false;
                const newContents = state.visualEditorBlocks.map((item) => {
                    if (item.locale === action.payload.locale) {
                        replaced = true;
                        return {
                            ...item,
                            content: newBlocks
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    visualEditorBlocks: replaced ?
                        newContents :
                        state.visualEditorBlocks.concat([{ locale: action.payload.locale, content: newBlocks }])
                };
            }
            return state;
        }
        case CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT: {
            const firstBlocks = state.visualEditorBlocks[0];
            const blocks = state.visualEditorBlocks.find(({ locale }) => locale === action.payload.locale);

            if (!blocks && firstBlocks) {
                return {
                    ...state,
                    visualEditorBlocks: state.visualEditorBlocks.concat([
                        {
                            locale: action.payload.locale,
                            content: firstBlocks.content.map((item) => item.clone())
                        }
                    ])
                };
            } else if (!blocks && !firstBlocks) {
                return {
                    ...state,
                    visualEditorBlocks: state.visualEditorBlocks.concat([
                        { locale: action.payload.locale, content: [] }
                    ])
                };
            }

            return state;
        }
        case DELETE_VISUAL_EDITOR_BLOCK: {
            return {
                ...state,
                visualEditorBlocks: state.visualEditorBlocks.map((item) => {
                    if (item.locale === action.payload.locale) {
                        return {
                            ...item,
                            content: item.content.filter((block) => {
                                return block.getId() !== action.payload.id;
                            })
                        };
                    }
                    return item;
                })
            };
        }
        case DUPLICATE_VISUAL_EDITOR_BLOCK: {
            const blocks = state.visualEditorBlocks.find(({ locale }) => locale === action.payload.locale);
            const selectedBlockIndex = blocks?.content.findIndex((block) => block.getId() === action.payload.id);
            const selectedBlock = blocks?.content[isNumber(selectedBlockIndex) ? selectedBlockIndex : -1];
            if (blocks && selectedBlock && isNumber(selectedBlockIndex)) {
                const block = selectedBlock.clone();

                const newBlocks = [...blocks.content];
                newBlocks.splice(selectedBlockIndex + 1, 0, block);
                return {
                    ...state,
                    visualEditorBlocks: state.visualEditorBlocks.map((item) => {
                        if (item.locale === action.payload.locale) {
                            return {
                                ...item,
                                content: newBlocks
                            };
                        }
                        return item;
                    })
                };
            }
            return state;
        }
        case REORDER_VISUAL_EDITOR_BLOCKS: {
            const blocks = state.visualEditorBlocks.find(({ locale }) => locale === action.payload.locale)?.content;
            const block = findBlockRecursively(blocks ?? [], action.payload.id);
            if (blocks && block) {
                let result = insertBlockAtIndex({
                    index: action.payload.index,
                    block,
                    blocks
                });
                result = result.filter((item, index) => {
                    return item.getId() !== action.payload.id || index === action.payload.index;
                });
                return {
                    ...state,
                    visualEditorBlocks: state.visualEditorBlocks.map((item) => {
                        if (item.locale === action.payload.locale) {
                            return { ...item, content: result };
                        }
                        return item;
                    })
                };
            }
            return state;
        }
        case RESET_EDITORS: {
            return {
                ...state,
                visualEditorMode: Mode.DESKTOP,
                visualEditorBlocks: [],
                visualEditorBodyStyles: {
                    fontFamily: defaultFont,
                    backgroundColor: "#fff",
                    width: 600,
                    padding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    },
                    innerBorder: {
                        color: '#000',
                        width: 0
                    },
                    innerBackgroundColor: '#fff',
                    innerPadding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    }
                }
            };
        }
        case RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML: {
            const blocks = {
                locale: action.payload.locale,
                content: htmlWalker(
                    action.payload.html,
                    (node) => {
                        const element = node as HTMLElement;

                        if (element.dataset.blockRoot) {
                            return restorer.restoreFromVersion(
                                extractVersionFromHtml(action.payload.html),
                                {
                                    type: element.dataset.blockRoot,
                                    html: element.outerHTML,
                                    quotationCode: action.payload.quotationCode
                                }
                            );
                        }

                        return null;
                    },
                    new MailTemplateBodyHtmlWalkingStrategy()
                ).filter((item) => item) as Block<any>[]
            };

            let alreadyExists = false;
            const visualEditorBlocks = state.visualEditorBlocks.map((item) => {
                if (item.locale === blocks.locale) {
                    alreadyExists = true;
                    return blocks;
                }
                return item;
            });

            const { document } = createDomObjectsFromHtml(action.payload.html);
            let fontFamily = defaultFont;
            document.querySelectorAll('link').forEach((node) => {
                const url = new URL(node.href);
                if (url.searchParams.get('global') === 'true') {
                    fontFamily = {
                        name: url.searchParams.get('family') ?? 'Unknown font',
                        url: node.href
                    };
                }
            });
            const bodyOptionsElement = document.body.querySelector('div');
            const bodyWrapper: HTMLDivElement | null | undefined = bodyOptionsElement?.querySelector('.body-wrapper');
            const bodyWrapperTD = bodyOptionsElement?.querySelector('td');

            return {
                ...state,
                visualEditorBlocks: alreadyExists ?
                    visualEditorBlocks :
                    [...state.visualEditorBlocks, blocks],
                visualEditorBodyStyles: {
                    fontFamily,
                    backgroundColor: rgbToHex(
                        bodyOptionsElement?.style.backgroundColor,
                        'rgb(255, 255, 255)'
                    ),
                    width: parseInt(bodyOptionsElement?.dataset.width ?? '200'),
                    padding: {
                        top: parseInt(bodyOptionsElement?.style.paddingTop ?? '0'),
                        bottom: parseInt(bodyOptionsElement?.style.paddingBottom ?? '0'),
                        left: parseInt(bodyOptionsElement?.style.paddingLeft ?? '0'),
                        right: parseInt(bodyOptionsElement?.style.paddingRight ?? '0')
                    },
                    innerBorder: {
                        color: rgbToHex(
                            bodyWrapperTD?.style.borderColor,
                            '#000'
                        ),
                        width: parseInt(bodyWrapperTD?.style.borderWidth ?? '0')
                    },
                    innerBackgroundColor: rgbToHex(
                        bodyWrapper?.style.backgroundColor,
                        '#fff'
                    ),
                    innerPadding: {
                        top: parseInt(bodyWrapper?.style.paddingTop ?? '0'),
                        bottom: parseInt(bodyWrapper?.style.paddingBottom ?? '0'),
                        left: parseInt(bodyWrapper?.style.paddingLeft ?? '0'),
                        right: parseInt(bodyWrapper?.style.paddingRight ?? '0')
                    }
                }
            };
        }
        case SET_VISUAL_EDITOR_BLOCK_OPTIONS: {
            return {
                ...state,
                visualEditorBlocks: state.visualEditorBlocks.map((item) => {
                    if (item.locale === action.payload.locale) {
                        return {
                            ...item,
                            content: item.content.map((block) => {
                                if (block.getId() === action.payload.id) {
                                    return block.clone(action.payload.options);
                                }

                                if (block instanceof ColumnsBlock) {
                                    const options = block.getOptions();
                                    return block.clone({
                                        ...options,
                                        columns: options.columns.map((column) => {
                                            return {
                                                ...column,
                                                blocks: column.blocks.map((block) => {
                                                    if (block.getId() === action.payload.id) {
                                                        return block.clone(action.payload.options);
                                                    }
                                                    return block;
                                                })
                                            };
                                        })
                                    });
                                }

                                return block;
                            })
                        };
                    }
                    return item;
                })
            };
        }
        case SET_VISUAL_EDITOR_BODY_STYLES: {
            return { ...state, visualEditorBodyStyles: action.payload };
        }
        case SET_VISUAL_EDITOR_MODE: {
            return { ...state, visualEditorMode: action.payload };
        }
        default: return state;
    }
};

export default combineReducers({
    others: MailTemplateReducer,
    visualEditor: undoable(
        VisualEditorReducer,
        {
            undoType: UNDO_VISUAL_EDITOR,
            redoType: REDO_VISUAL_EDITOR,
            filter: includeAction([
                ADD_VISUAL_EDITOR_BLOCK,
                DELETE_VISUAL_EDITOR_BLOCK,
                DUPLICATE_VISUAL_EDITOR_BLOCK,
                REORDER_VISUAL_EDITOR_BLOCKS,
                SET_VISUAL_EDITOR_BLOCK_OPTIONS
            ])
        }
    )
});

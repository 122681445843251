import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { CartState } from '../objects/cartState';
import { CartStepperName } from '../objects/cartStepperName';
import { format } from 'date-fns';
import { ManualProduct } from '../objects/manualProduct';

const initialState: CartState = {
    manual_products: [],
    automatic_products_open: false,
    activeStepper: CartStepperName.CART,
    dataStepReservation: {accommodation: [],total: 0},
    dataToSaveAction:{dataToSaveAction:false},
    dataToSaveStatus:{dataToSaveStatus:false},
    pageReservation:{pageReservation:0},
    updateStatusAction:{updateStatusAction:false},
    statusUpdateTravels:[],
    manual_product_form: false,
    manual_product_id: null,
    manual_product_name: '',
    manual_product_type: 16,
    manual_product_destination_associated: -1,
    manual_product_provider_id: '',
    manual_product_provider_name: '',
    manual_product_groups: [],
    manual_product_booking_status: 'DEFAULT',
    manual_product_booking_ref: '',
    manual_product_start_date: null,
    manual_product_end_date: null,
    manual_product_address: '',
    manual_product_stars: 0,
    manual_product_cgos_reduction: false,
    manual_product_rooms: [{
        name: '',
        description_list: [],
        group: [],
        breakfast_included: false,
        lunch_included: false,
        dinner_included: false,
        all_inclusive: false
    }],
    manual_product_resort_fees: null,
    manual_product_resort_fees_currency: null,
    manual_product_flight_paste: null,
    manual_product_baggages: 0,
    manual_product_flight_taxes: 0,
    manual_product_purchase_price: 0,
    manual_product_purchase_currency: 47,
    manual_product_margin_type: 'PER',
    manual_product_margin_value: 0,
    manual_product_price_id: null,
    manual_product_description: '',
    manual_product_cancellation_note: '',
    manual_product_no_refund: false,
    manual_product_note: '',
    manual_product_picture: null,
    manual_product_picture_modal: false,
    manual_product_start_hour: '00:00',
    manual_product_end_hour: '00:00',
    manual_product_breakfast_included: false,
    manual_product_lunch_included: false,
    manual_product_diner_included: false,
    manual_product_tva: 0,
    manual_product_forced_tva: false,
    manual_item_list: [],
    roomChangePrices: {},
    isEditLate:false,
    blocs_create: false,
    blocs: [{accommodation: null, contract: null, end_date: null, room: null, room_nb: 1, start_date: null}],
    travelerPatchDone: 0,
    show_only_request_modal: false
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setIsEditLater: (
            state,
            action: PayloadAction<boolean>
        ): void => {
            state.isEditLate = action.payload;
        },
        setStepper: (
            state,
            action: PayloadAction<CartStepperName>
        ): void => {
            state.activeStepper = action.payload;
        },
        setDataReservation:(
            state,
            action: PayloadAction<{accommodation: any,total: number}>
        ): void => {
            state.dataStepReservation = action.payload;
        },
        setDataToSaveAction:(
            state,
            action: PayloadAction<{dataToSaveAction: boolean}>
        ): void => {
            state.dataToSaveAction= action.payload;
        },
        setStatusPay:(
            state,
            action: PayloadAction<{dataToSaveStatus: boolean}>
        ): void => {
            state.dataToSaveStatus= action.payload;
        },
        setPageReservation:(
            state,
            action: PayloadAction<{pageReservation: number}>
        ): void => {
            state.pageReservation= action.payload;
        },
        setUpdateStatusAction:(
            state,
            action: PayloadAction<{updateStatusAction: boolean}>
        ): void => {
            state.updateStatusAction= action.payload;
        },
        setStatusUpdateTravels:(
            state,
            action: PayloadAction<{item: boolean}>
        ): void => {
            state.statusUpdateTravels.push(action.payload);
        },
        setManualProducts: (state, action) => {
            state.manual_products = action.payload;
        },
        setManualProduct: (state, action) => {
            const { manual_product, user, quotation_code } = action.payload;
            if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < manual_product.prices.length; i++) {
                    if (manual_product.prices[i].master_price) {
                        state.manual_product_purchase_price = manual_product.prices[i].purchase_price;
                        state.manual_product_purchase_currency = manual_product.prices[i].purchase_currency;
                        state.manual_product_margin_type = manual_product.prices[i].custom_rate_type;
                        state.manual_product_margin_value = manual_product.prices[i].custom_value;
                        state.manual_product_price_id = manual_product.prices[i].id;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < manual_product.prices.length; i++) {
                    if (manual_product.prices[i].owner === user.client) {
                        state.manual_product_purchase_price = manual_product.prices[i].purchase_price;
                        state.manual_product_purchase_currency = manual_product.prices[i].purchase_currency;
                        state.manual_product_margin_type = manual_product.prices[i].custom_rate_type;
                        state.manual_product_margin_value = manual_product.prices[i].custom_value;
                        state.manual_product_price_id = manual_product.prices[i].id;
                        break;
                    }
                }
            }
            state.manual_product_form = true;
            state.manual_product_id = manual_product.id;
            state.manual_product_name = manual_product.name;
            state.manual_product_type = manual_product.flight_paste !== null && manual_product.flight_paste !== '' ? 61 : manual_product.product_type;
            state.manual_product_destination_associated = manual_product.step;
            state.manual_product_provider_id = manual_product.provider_id === null && manual_product.provider_name !== null ? null : manual_product.provider_id;
            state.manual_product_provider_name = manual_product.provider_name === null ? '' : manual_product.provider_name;
            state.manual_product_groups = manual_product.group_passenger !== null ? manual_product.group_passenger.travelers : [];
            state.manual_product_start_date = manual_product.start_date;
            state.manual_product_start_hour = format(new Date(manual_product.start_date), "HH:mm");
            state.manual_product_end_date = manual_product.end_date;
            state.manual_product_end_hour = format(new Date(manual_product.end_date), "HH:mm");
            state.manual_product_address = manual_product.address === null ? '' : manual_product.address;
            state.manual_product_stars = manual_product.stars;
            state.manual_product_cgos_reduction = manual_product.cgos_reduction;
            state.manual_product_rooms = manual_product.rooms;
            state.manual_product_rooms.map(room => {
                if (room.group_passenger !== null) {
                    room.group = room.group_passenger.travelers;
                } else {
                    room.group = [];
                }
            });
            state.manual_product_resort_fees = manual_product.resort_fees;
            state.manual_product_resort_fees_currency = manual_product.resort_fees_currency;
            state.manual_product_flight_paste = manual_product.flight_paste;
            state.manual_product_baggages = manual_product.nb_baggages;
            state.manual_product_flight_taxes = manual_product.taxes;
            state.manual_product_description = manual_product.description === null ? '' : manual_product.description;
            state.manual_product_cancellation_note = manual_product.cancel_condition_text === null ? '' : manual_product.cancel_condition_text;
            state.manual_product_no_refund = manual_product.no_refund;
            state.manual_product_note = manual_product.custom_information === null ? '' : manual_product.custom_information;
            state.manual_product_picture = manual_product.picture;
        },
        addManualProduct: (state, action) => {
            const tmp_cart = [...state.manual_products];
            tmp_cart.push(action.payload);
            //            tmp_cart = [].concat(tmp_cart, action.payload.manual_item_list);
            const no_date_cart = [];
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].start_date === null) {
                    no_date_cart.push(tmp_cart[i]);
                    tmp_cart.splice(i, 1);
                }
            }
            let sorted_cart = tmp_cart.sort(function(a, b) {
                const x = a.start_date;
                const y = b.start_date;
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
            if (no_date_cart.length > 0)
                sorted_cart = [].concat(sorted_cart, no_date_cart);
            state.manual_products = sorted_cart;
            state.manual_product_form = false;
            state.manual_product_id = null;
            state.manual_product_name = '';
            state.manual_product_type = 0;
            state.manual_product_destination_associated = -1;
            state.manual_product_provider_id = null;
            state.manual_product_provider_name = '';
            state.manual_product_groups = [];
            state.manual_product_booking_status = 'DEFAULT';
            state.manual_product_booking_ref = '';
            state.manual_product_start_date = null;
            state.manual_product_end_date = null;
            state.manual_product_start_hour = '00:00';
            state.manual_product_end_hour = '00:00';
            state.manual_product_address = '';
            state.manual_product_stars = 0;
            state.manual_product_cgos_reduction = false;
            state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            state.manual_product_resort_fees = null;
            state.manual_product_resort_fees_currency = null;
            state.manual_product_flight_paste = null;
            state.manual_product_baggages = 0;
            state.manual_product_flight_taxes = 0;
            state.manual_product_purchase_price = 0;
            state.manual_product_purchase_currency = 47;
            state.manual_product_margin_type = 'PER';
            state.manual_product_margin_value = 0;
            state.manual_product_price_id = null;
            state.manual_product_description = '';
            state.manual_product_cancellation_note = '';
            state.manual_product_no_refund = false;
            state.manual_product_note = '';
            state.manual_product_picture = null;
        },
        editManualProduct: (state, action) => {
            const tmp_cart = [...state.manual_products];
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].id === action.payload.id) {
                    tmp_cart[i] = action.payload;
                    break;
                }
            }
            const no_date_cart = [];
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].start_date === null) {
                    no_date_cart.push(tmp_cart[i]);
                    tmp_cart.splice(i, 1);
                }
            }
            let sorted_cart = tmp_cart.sort(function(a, b) {
                const x = a.start_date;
                const y = b.start_date;
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
            if (no_date_cart.length > 0)
                sorted_cart = [].concat(sorted_cart, no_date_cart);
            state.manual_products = sorted_cart;
            state.manual_product_form = false;
            state.manual_product_id = null;
            state.manual_product_name = '';
            state.manual_product_type = 16;
            state.manual_product_destination_associated = -1;
            state.manual_product_provider_id = null;
            state.manual_product_provider_name = '';
            state.manual_product_groups = [];
            state.manual_product_booking_status = 'DEFAULT';
            state.manual_product_booking_ref = '';
            state.manual_product_start_date = null;
            state.manual_product_end_date = null;
            state.manual_product_start_hour = '00:00';
            state.manual_product_end_hour = '00:00';
            state.manual_product_address = '';
            state.manual_product_stars = 0;
            state.manual_product_cgos_reduction = false;
            state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            state.manual_product_resort_fees = null;
            state.manual_product_resort_fees_currency = null;
            state.manual_product_flight_paste = null;
            state.manual_product_baggages = 0;
            state.manual_product_flight_taxes = 0;
            state.manual_product_purchase_price = 0;
            state.manual_product_purchase_currency = 47;
            state.manual_product_margin_type = 'PER';
            state.manual_product_margin_value = 0;
            state.manual_product_price_id = null;
            state.manual_product_description = '';
            state.manual_product_cancellation_note = '';
            state.manual_product_no_refund = false;
            state.manual_product_note = '';
            state.manual_product_picture = null;
            state.manual_product_tva = 0;
            state.manual_product_forced_tva = false;
        },
        setManualProductDates: (state, action) => {
            state.manual_product_start_date = action.payload.start_date;
            state.manual_product_end_date = action.payload.end_date;
        },
        setManualProductStartDates: (state, action) => {
            state.manual_product_start_date = action.payload;
        },
        setManualProductEndDates: (state, action) => {
            state.manual_product_end_date = action.payload;
        },
        setManualProductName: (state, action) => {
            state.manual_product_name = action.payload;
        },
        setManualProductType: (state, action) => {
            state.manual_product_type = action.payload.value;
            state.manual_product_provider_id = null;
            state.manual_product_provider_name = '';
            if (state.manual_product_picture === null && action.payload.default_pictures !== null) {
                action.payload.default_pictures.map(picture => {
                    if ((picture.type === 'DEFAULT_EXCURSIONS' && (state.manual_product_type === 1 || state.manual_product_type === 12)) ||
                      (picture.type === 'DEFAULT_INSURANCES' && state.manual_product_type === 8) ||
                      (picture.type === 'DEFAULT_TRANSFERS' && state.manual_product_type === 4) ||
                      (picture.type === 'DEFAULT_TRAINS' && state.manual_product_type === 5)) {
                        state.manual_product_picture = picture;
                    }
                });
            }
        },
        setManualProductProvider: (state, action) => {
            state.manual_product_provider_id = action.payload;
        },
        setManualProductProviderName: (state, action) => {
            state.manual_product_provider_name = action.payload;
        },
        setManualProductCgosReduction: (state, action) => {
            state.manual_product_cgos_reduction = !state.manual_product_cgos_reduction;
        },
        setManualProductDescription: (state, action) => {
            state.manual_product_description = action.payload;
        },
        setManualProductNote: (state, action) => {
            state.manual_product_note = action.payload;
        },
        setManualProductCancel: (state, action) => {
            state.manual_product_form = false;
            state.manual_product_id = null;
            state.manual_product_name = '';
            state.manual_product_type = 16;
            state.manual_product_destination_associated = -1;
            state.manual_product_provider_id = null;
            state.manual_product_provider_name = '';
            state.manual_product_groups = [];
            state.manual_product_booking_status = 'DEFAULT';
            state.manual_product_booking_ref = '';
            state.manual_product_start_date = null;
            state.manual_product_end_date = null;
            state.manual_product_start_hour = '00:00',
            state.manual_product_end_hour = '00:00',
            state.manual_product_address = '';
            state.manual_product_stars = 0;
            state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            state.manual_product_breakfast_included = false;
            state.manual_product_lunch_included = false;
            state.manual_product_diner_included = false;
            state.manual_product_resort_fees = null;
            state.manual_product_resort_fees_currency = null;
            state.manual_product_flight_paste = null;
            state.manual_product_baggages = 0;
            state.manual_product_flight_taxes = 0;
            state.manual_product_purchase_price = 0;
            state.manual_product_purchase_currency = 47;
            state.manual_product_margin_type = 'PER';
            state.manual_product_margin_value = 0;
            state.manual_product_price_id = null;
            state.manual_product_description = '';
            state.manual_product_note = '';
            state.manual_product_picture = null;
            state.manual_product_tva = 0;
            state.manual_product_forced_tva = false;
        },
        toggleManualProductPicture: (state, action) => {
            state.manual_product_picture_modal = !state.manual_product_picture_modal;
        },
        setManualProductStartHour: (state, action) => {
            state.manual_product_start_hour = action.payload;
        },
        setManualProductEndHour: (state, action) => {
            state.manual_product_end_hour = action.payload;
        },
        removeManualProductPicture: (state, action) => {
            state.manual_product_picture = action.payload;
            state.manual_product_picture_modal = false;
        },
        setManualProductCancellationNote: (state, action) => {
            state.manual_product_cancellation_note = action.payload;
        },
        setManualProductNoRefund: (state, action) => {
            state.manual_product_no_refund = action.payload;
        },
        setManualProductAddress: (state, action) => {
            state.manual_product_address = action.payload;
        },
        setManualProductStars: (state, action) => {
            state.manual_product_stars = action.payload;
        },
        setManualProductRoomsNb: (state, action) => {
            const size = action.payload;
            const rooms = [...state.manual_product_rooms];
            if (rooms.length < size) {
                while (rooms.length < size) {
                    rooms.push({
                        name: '',
                        description_list: [],
                        group: [],
                        breakfast_included: false,
                        lunch_included: false,
                        dinner_included: false,
                        all_inclusive: false
                    });
                }
            } else {
                rooms.splice(size - 1, rooms.length - size);
            }
            state.manual_product_rooms = rooms;
        },
        setManualProductRoomName: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload.index]};
            room.name = action.payload.value;
            rooms[action.payload.index] = room;
            state.manual_product_rooms = rooms;
        },
        setManualProductRoomDescription: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload.index_room]};
            room.description_list[action.payload.index_description] = action.payload.value;
            rooms[action.payload.index] = room;
            state.manual_product_rooms = rooms;
        },
        deleteManualProductRoomDescription: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload.index_room]};
            room.description_list.splice(action.payload.index_description, 1);
            rooms[action.payload.index] = room;
            state.manual_product_rooms = rooms;
        },
        addManualProductRoomDescription: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload]};
            room.description_list.push('');
            rooms[action.payload] = room;
            state.manual_product_rooms = rooms;
        },
        toggleManualProductRoomTraveler: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload.index_room]};
            let has_traveler = false;
            for (let i = 0; i < room.group.length; i++) {
                if (room.group[i] === action.payload.traveler_id) {
                    room.group.splice(i, 1);
                    has_traveler = true;
                    break;
                }
            }
            if (!has_traveler) {
                for (let i = 0; i < rooms.length; i++) {
                    for (let j = 0; j < rooms[i].group.length; j++) {
                        if (rooms[i].group[j] === action.payload.traveler_id) {
                            rooms[i].group.splice(j, 1);
                            break;
                        }
                    }
                }
                room.group.push(action.payload.traveler_id);
            }
            rooms[action.payload.index_room] = room;
            state.manual_product_rooms = rooms;
        },
        setManualProductBreakfastIncluded: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload]};
            if (!room.all_inclusive) {
                room.breakfast_included = !room.breakfast_included;
            }
            rooms[action.payload] = room;
            state.manual_product_rooms = rooms;
        },
        setManualProductLunchIncluded: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload]};
            if (!room.all_inclusive) {
                room.lunch_included = !room.lunch_included;
            }
            rooms[action.payload] = room;
            state.manual_product_rooms = rooms;
        },
        setManualProductDinerIncluded: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload]};
            if (!room.all_inclusive) {
                room.dinner_included = !room.dinner_included;
            }
            rooms[action.payload] = room;
            state.manual_product_rooms = rooms;
        },
        setManualProductAllInclusive: (state, action) => {
            const rooms = [...state.manual_product_rooms];
            const room = {...rooms[action.payload]};
            room.all_inclusive = !room.all_inclusive;
            if (room.all_inclusive) {
                room.breakfast_included = false;
                room.lunch_included = false;
                room.dinner_included = false;
            }
            rooms[action.payload] = room;
            state.manual_product_rooms = rooms;
        },
        setManualProductResortFees: (state, action) => {
            state.manual_product_resort_fees = action.payload;
        },
        setManualProductResortFeesCurrency: (state, action) => {
            state.manual_product_resort_fees_currency = action.payload;
        },
        setManualProductRooms: (state, action) => {
            state.manual_product_rooms = action.payload;
        },
        setManualProductFlightPaste: (state, action) => {
            state.manual_product_flight_paste = action.payload;
        },
        setManualProductBaggage: (state, action) => {
            state.manual_product_baggages = action.payload;
        },
        setManualProductFlightTaxes: (state, action) => {
            state.manual_product_flight_taxes = action.payload;
        },
        setManualProductPurchasePrice: (state, action) => {
            state.manual_product_purchase_price = action.payload;
        },
        setManualProductPurchaseCurrency: (state, action) => {
            state.manual_product_purchase_currency = action.payload;
        },
        setManualProductMarginType: (state, action) => {
            state.manual_product_margin_type = action.payload;
        },
        setManualProductMarginValue: (state, action) => {
            state.manual_product_margin_value = action.payload;
        },
        setManualProductPicture: (state, action) => {
            state.manual_product_picture = action.payload;
            state.manual_product_picture_modal = false;
        },
        setManualProductForm: (state) => {
            state.manual_product_form = true;
        },
        deleteManualProduct: (state, action) => {
            state.manual_products = [...state.manual_products].filter(product => product.id !== action.payload);
        },
        setManualProductTva: (state, action) => {
            state.manual_product_tva = action.payload;
        },
        setManualProductForcedTva: (state, action) => {
            state.manual_product_forced_tva = action.payload;
        },
        updateManualProductsAfterBooking: (
            state,
            action: PayloadAction<ManualProduct[]>
        ): void => {
            state.manual_products = state.manual_products.map((item) => {
                const replacement = action.payload.find((product) => product.id === item.id);
                return replacement ?? item;
            });
        },
        upsertManualProducts: (
            state,
            action: PayloadAction<ManualProduct[]>
        ) => {
            const products = [...action.payload];
            state.manual_products = state.manual_products.map((item) => {
                const matchIndex = products.findIndex((product) => product.id === item.id);
                const match = products[matchIndex];

                if (match) {
                    products.splice(matchIndex, 1);
                    return match;
                }

                return item;
            });
            state.manual_products = state.manual_products.concat(products);
        },
        setRoomChangeVariants: (
            state,
            action: PayloadAction<{roomId: number, data: CartState['roomChangePrices'][number]}>
        ): void => {
            state.roomChangePrices[action.payload.roomId] = action.payload.data;
        },
        toggleBlocAccommodationForm: (state): void => {
            state.blocs_create = !state.blocs_create;
        },
        addBloc: (state) => {
            state.blocs = [...state.blocs, {accommodation: null, contract: null, end_date: state.end_date, room: null, room_nb: 1, start_date: state.start_date}];
        },
        deleteBloc: (state, action: PayloadAction<number>) => {
            const blocs = [...state.blocs];
            blocs.splice(action.payload, 1);
            state.blocs = blocs;
        },
        updateBlocAccommodation: (state, action: PayloadAction<{bloc_index: number, accommodation: null | ManualAccommodation}>) => {
            const { bloc_index, accommodation } = action.payload;
            const blocs = [...state.blocs];
            const bloc = {...blocs[bloc_index]};
            bloc.accommodation = accommodation;
            bloc.contract = null;
            bloc.room = null;
            blocs[bloc_index] = bloc;
            state.blocs = blocs;
        },
        updateBlocContract: (state, action: PayloadAction<{bloc_index: number, contract: null | Contract}>) => {
            const { bloc_index, contract } = action.payload;
            const blocs = [...state.blocs];
            const bloc = {...blocs[bloc_index]};
            bloc.contract = contract;
            bloc.room = null;
            blocs[bloc_index] = bloc;
            state.blocs = blocs;
        },
        updateBlocEndDate: (state, action: PayloadAction<{bloc_index: number, date: null | Date}>) => {
            const { bloc_index, date } = action.payload;
            const blocs = [...state.blocs];
            const bloc = {...blocs[bloc_index]};
            bloc.end_date = date;
            blocs[bloc_index] = bloc;
            state.blocs = blocs;
        },
        updateBlocRoom: (state, action: PayloadAction<{bloc_index: number, room: null | Room}>) => {
            const { bloc_index, room } = action.payload;
            const blocs = [...state.blocs];
            const bloc = {...blocs[bloc_index]};
            bloc.room = room;
            blocs[bloc_index] = bloc;
            state.blocs = blocs;
        },
        updateBlocRoomNb: (state, action: PayloadAction<{bloc_index: number, room_nb: string | number}>) => {
            const { bloc_index, room_nb } = action.payload;
            const blocs = [...state.blocs];
            const bloc = {...blocs[bloc_index]};
            bloc.room_nb = room_nb;
            blocs[bloc_index] = bloc;
            state.blocs = blocs;
        },
        updateBlocStartDate: (state, action: PayloadAction<{bloc_index: number, date: null | Date}>) => {
            const { bloc_index, date } = action.payload;
            const blocs = [...state.blocs];
            const bloc = {...blocs[bloc_index]};
            bloc.start_date = date;
            blocs[bloc_index] = bloc;
            state.blocs = blocs;
        },
        updateTravelerPatchDone: (state) => {
            state.travelerPatchDone += 1;
        },
        setTravelerPatchDone: (state, action: PayloadAction<number>) => {
            state.travelerPatchDone = action.payload;
        },
        toggleOnlyRequestModal: (state) => {
            state.show_only_request_modal = !state.show_only_request_modal;
        },
        setAutomaticProductsOpen: (state) => {
            state.automatic_products_open = !state.automatic_products_open
        }
    }
});


export const {
    setStepper, setDataReservation,setDataToSaveAction,setStatusPay,setPageReservation,setUpdateStatusAction, setStatusUpdateTravels, addManualProduct, editManualProduct, setManualProducts, setManualProduct, setManualProductDates, setManualProductName,
    setManualProductType, setManualProductProvider, setManualProductProviderName, setManualProductCgosReduction, setManualProductDescription, setManualProductNote, setManualProductCancel, toggleManualProductPicture, setManualProductStartHour,
    setManualProductEndHour, removeManualProductPicture, setManualProductCancellationNote, setManualProductNoRefund, setManualProductAddress, setManualProductStars, setManualProductRoomsNb, setManualProductRoomName,
    setManualProductRoomDescription, deleteManualProduct, deleteManualProductRoomDescription, addManualProductRoomDescription, toggleManualProductRoomTraveler, setManualProductBreakfastIncluded, setManualProductLunchIncluded,
    setManualProductDinerIncluded, setManualProductAllInclusive, setManualProductResortFees, setManualProductResortFeesCurrency, setManualProductRooms, setManualProductFlightPaste, setManualProductBaggage,
    setManualProductFlightTaxes, setManualProductPurchasePrice, setManualProductPurchaseCurrency, setManualProductMarginType, setManualProductMarginValue, setManualProductPicture, setManualProductForm, setManualProductStartDates, setManualProductEndDates, setManualProductTva,
    setManualProductForcedTva,
    updateManualProductsAfterBooking,
    upsertManualProducts,
    setRoomChangeVariants,
    setIsEditLater,
    toggleOnlyRequestModal, setAutomaticProductsOpen,
    toggleBlocAccommodationForm, addBloc, deleteBloc, updateBlocAccommodation, updateBlocContract, updateBlocEndDate, updateBlocRoom, updateBlocRoomNb, updateBlocStartDate, setTravelerPatchDone, updateTravelerPatchDone
} = cartSlice.actions;

export default cartSlice;